<template>
  <b-container fluid class="container__login">
    <div class="login__content">
      <img src="@/assets/images/logologin.png" alt="" class="w-100 mb-3" />
      <h2 class="title__login">Buscar registros pendientes de pasajeros</h2>
      <div
        class="panel__msg--error d-flex justify-content-between"
        v-if="statusMsgError"
      >
        <small class="msg__error mt-1">{{ msgError }}</small>
        <b-button class="btn btn__close--panel" @click="closedPanel">
          <b-icon-x />
        </b-button>
      </div>
      <b-form @submit.prevent="search" autocomplete="off">
        <div class="text-reg">
          <p>*Ingresar el número de identificación del titular de la reserva.</p>
          <p>*Ingresar la fecha de viaje.</p>
        </div>
        <div class="input__field">
          <span class="icono__input">
            <b-icon-person-badge-fill />
          </span>
          <input
            type="number"
            placeholder="N° de identificación"
            v-model="formData.identification"
            autofocus
          />
        </div>
        <div class="input__field">
          <date-picker
            v-model="formData.departure_date"
            :attributes="attrs"
            :model-config="modelConfig"
            :available-dates="{ start: new Date(), end: null }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                class="input-date"
                readonly
              />
            </template>
          </date-picker>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            type="submit"
            class="btn__ingresar mt-3 mb-2"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" small v-if="isBusy" />
            Buscar
          </b-button>
        </div>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import {
  BIconPersonBadgeFill,
  BIconX,
  BButton,
  BForm,
  BSpinner,
  BContainer,
} from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
moment.locale("es");

export default {
  components: {
    BIconPersonBadgeFill,
    BIconX,
    BButton,
    BForm,
    BSpinner,
    BContainer,
    DatePicker,
  },
  props: {
    statusMsgError: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    msgError: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      formData: {
        identification: "",
        enterpriseUuid: me.$route.query.uuid,
        departure_date: new Date(),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
    };
  },

  validations: {
    formData: {
      identification: {
        required,
        numeric,
      },
      departure_date: {
        required,
      }
    },
  },

  methods: {
    search() {
      const me = this;
      me.formData.departure_date = moment(me.formData.departure_date).format("YYYY-MM-DD");
      me.$emit("search", me.formData);
    },
    closedPanel() {
      const me = this;
      me.$emit("closedPanel");
    },
  },
};
</script>
<style>
.text-reg {
  text-align: left;
  margin-bottom: 15px;
}
.text-reg p {
  margin-bottom: 5px;
}
</style>