<template>
  <div>
    <b-container fluid class="background-register">
      <b-card no-body class="card-global">
        <b-row class="mb-1">
          <b-col cols="12">
            <h3 class="text-center">
              Registros de pasajeros pendientes
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12" class="mb-3">
            <p for="name" class="text-black mb-0">
              La persona que realizó la reserva de los tiquetes es el señor(a) <strong>{{ record?.client }}</strong>, en la empresa
              <strong>{{ record?.enterprise }}</strong>, te pedimos el favor que si no conoces a esta persona ó no sabes al respecto de esta reserva, no diligencies ningún formulario.
              <br />
              <strong>
                Por favor, solo debes llenar el ó los registro(s) que correspondan al número de tiquetes que hallas comprado, esto ayudará a evitar retrasos el día del viaje. Agradecemos tu comprensión.
              </strong>
            </p><br />
            <p for="name" class="text-black mb-0">Tienes <b-badge variant="primary">{{ accompanists.length }}</b-badge> registro(s) pendiente(s).</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col 
            xl="6" lg="6" md="12" sm="12" class="mb-3"
            v-for="(item, index) in accompanists"
            :key="index"
          >
            <form-accompanist 
              :item="item"
              :travelUuid="travelUuid"
              @updateList="() => $emit('updateList')"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BCard, BBadge } from "bootstrap-vue";
import FormAccompanist from "./FormAccompanist";

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BBadge,
    FormAccompanist,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    accompanists: {
      type: Array,
      default: () => [],
    },
    travelUuid: {
      type: String,
      default: null,
    }
  },
}
</script>

<style lang="css">
.background-register {
  padding: 40px;
  min-height: 100vh;
  background-image: linear-gradient(var(--third-color), var(--fourth-color), var(--second-color), var(--primary-color));
}

/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px)
{
  .background-register {
    padding: 5px;
  }
}
</style>