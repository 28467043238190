<template>
  <div class="box-regiter">
    <b-form @submit.prevent="saveAccompanist" ref="form" autocomplete="off">
      <b-row>
        <b-col lg="12" md="12" sm="12" class="text-center mt-2">
          <div class="label_input">Registro N° {{ item.number }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="text-center mt-2">
          <div class="label_input">Buscar pasajero</div>
        </b-col>
        <b-col lg="6" md="8" sm="12">
          <div role="group">
            <label for="identification" class="label_input"
              >Identificación* (Sin puntos, ni comas)</label
            >
            <div class="d-flex align-items-center">
              <b-form-input
                type="number"
                id="identification"
                size="sm"
                v-model="search"
              />
              <b-button
                type="button"
                class="btn btn-secondary ml-2 w-50"
                :disabled="isBusy2 || isValidateSearch"
                @click.prevent="searchAccompanist"
              >
                <b-spinner class="mr-2" v-if="isBusy2" small />
                Buscar
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isSearch">
        <b-col lg="12" md="12" sm="12" class="text-center mb-2 mt-2">
          <div class="label_input">Datos del pasajero</div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="names" class="label_input">Nombres*</label>
            <b-form-input id="names" size="sm" v-model="formData.names" />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="last_names" class="label_input">Apellidos*</label>
            <b-form-input
              id="last_names"
              size="sm"
              v-model="formData.last_names"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Tipo de identificación*</label>
            <v-select
              :options="typeIndetificatios"
              label="value"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.type_identification"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="identification" class="label_input"
              >Identificación*</label
            >
            <b-form-input
              id="identification"
              size="sm"
              type="number"
              v-model="$v.formData.identification.$model"
              @blur="$v.formData.identification.$touch()"
            />
            <span
              v-if="$v.formData.identification.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.identification) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="cellphone" class="label_input">Celular*</label>
            <b-form-input
              id="cellphone"
              type="number"
              size="sm"
              v-model="$v.formData.cellphone.$model"
              @blur="$v.formData.cellphone.$touch()"
            />
            <span
              v-if="$v.formData.cellphone.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.cellphone) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="age" class="label_input">Edad*</label>
            <b-form-input
              id="age"
              size="sm"
              v-model="$v.formData.age.$model"
              @blur="$v.formData.age.$touch()"
            />
            <span
              v-if="$v.formData.age.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.age) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="eps" class="label_input">EPS*</label>
            <b-form-input
              id="eps"
              size="sm"
              v-model="$v.formData.eps.$model"
              @blur="$v.formData.eps.$touch()"
            />
            <span
              v-if="$v.formData.eps.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.eps) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="family" class="label_input">Nombre de familiar*</label>
            <b-form-input
              id="family"
              size="sm"
              v-model="$v.formData.family.$model"
              @blur="$v.formData.family.$touch()"
            />
            <span
              v-if="$v.formData.family.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.family) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="cellphone_family" class="label_input"
              >Celular de familiar*</label
            >
            <b-form-input
              id="cellphone_family"
              type="number"
              size="sm"
              v-model="$v.formData.cellphone_family.$model"
              @blur="$v.formData.cellphone_family.$touch()"
            />
            <span
              v-if="$v.formData.cellphone_family.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.cellphone_family) }}
            </span>
          </div>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="mb-3">
          <div role="group">
            <b-form-checkbox
              id="checkbox-1"
              v-model="isBaby"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              size="lg"
            >
              <label class="label_input"
                >¿Va acompaño(a) de un bebé?, recuerde que lo debe registrar
                solo si la edad cumple con las condiciones de la empresa.</label
              >
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isBaby">
        <b-col lg="12" md="12" sm="12" class="text-center mb-2 mt-2">
          <div class="label_input">Datos del bebé</div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="names_baby" class="label_input">Nombres*</label>
            <b-form-input
              id="names_baby"
              size="sm"
              v-model="formData.names_baby"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="last_names_baby" class="label_input">Apellidos*</label>
            <b-form-input
              id="last_names_baby"
              size="sm"
              v-model="formData.last_names_baby"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label class="label_input">Tipo de identificación*</label>
            <v-select
              :options="typeIndetificatios2"
              label="value"
              class="style-chooser"
              placeholder="Seleccione"
              v-model="formData.type_identification_baby"
            >
              <div slot="no-options">Sin opciones</div>
            </v-select>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="identification_baby" class="label_input"
              >Identificación*</label
            >
            <b-form-input
              id="identification_baby"
              size="sm"
              type="number"
              v-model="$v.formData.identification_baby.$model"
              @blur="$v.formData.identification_baby.$touch()"
            />
            <span
              v-if="$v.formData.identification_baby.$error"
              class="help-block text-danger text_error"
            >
              {{ messageValidation($v.formData.identification_baby) }}
            </span>
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="age_baby" class="label_input">Edad*</label>
            <b-form-input
              id="age_baby"
              size="sm"
              v-model="formData.age_baby"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="mb-3">
          <div role="group">
            <label for="eps_baby" class="label_input">Eps*</label>
            <b-form-input
              id="eps_baby"
              size="sm"
              v-model="formData.eps_baby"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isSearch">
        <b-col cols="12" class="d-flex justify-content-center mt-2">
          <b-button
            type="submit"
            size="lg"
            class="btn btn-success w-width"
            :disabled="isBusy || $v.$invalid"
          >
            <b-spinner class="mr-2" v-if="isBusy" small />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ["accompanistRepository", "registerAccompanistRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
    BFormCheckbox,
  },
  mixins: [ActionCRUD],
  props: {
    item: {
      type: Object,
      default: null,
    },
    travelUuid: {
      type: String,
      default: null,
    }
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      isBusy2: false,
      formData: {
        names: "",
        last_names: "",
        type_identification: null,
        identification: "",
        cellphone: "",
        age: null,
        eps: null,
        family: null,
        cellphone_family: null,
        accompanistUuid: me.item.uuid,
        names_baby: "",
        last_names_baby: "",
        type_identification_baby: "Registro civil",
        identification_baby: "",
        age_baby: "",
        eps_baby: "",
        travelUuid: me.travelUuid,
      },
      isBaby: false,
      typeIndetificatios: [
        "Cédula de ciudadanía",
        "Tarjeta de identidad",
        "Registro civil",
        "Pasaporte",
      ],
      typeIndetificatios2: ["Registro civil", "Pasaporte"],
      isSearch: false,
      search: "",
    };
  },
  computed: {
    isValidateSearch() {
      const me = this;
      let validate = true;

      validate = me.search.length > 1 ? false : true;

      return validate;
    },
  },
  validations() {
    const me = this;

    const defaultValidation = {
      names: {
        required,
      },
      last_names: {
        required,
      },
      type_identification: {
        required,
      },
      identification: {
        required,
        numeric,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      age: {
        required,
      },
      eps: {
        required,
      },
      family: {
        required,
      },
      cellphone_family: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    }

    return me.isBaby ? {
      formData: {
        names_baby: {
          required,
        },
        last_names_baby: {
          required,
        },
        type_identification_baby: {
          required,
        },
        identification_baby: {
          required,
          numeric,
        },
        age_baby: {
          required,
        },
        eps_baby: {
          required,
        },
        ...defaultValidation,
      }
    } : {
      formData: { ...defaultValidation }
    }
  },
  methods: {
    async saveAccompanist() {
      const me = this;
      try {
        me.isBusy = true;

        await me.registerAccompanistRepository.save(
          null,
          me.formData
        );
        me.messageSuccess();
        me.$emit("updateList");
      } catch (error) {
        const { message } = error.data;
        let title = 'Error al guardar registro' 
        let messageErr = 'Registro no guardado.';

        if (message == 'Identification is registered') {
          title = 'Error Identificación';
          messageErr = 'El pasajero ya se encuentra registrado en este tour.';
          me.clearField();
        }

        me.messageError(title, messageErr, "icon-message-error");
      } finally {
        me.isBusy = false;
      }
    },
    async searchAccompanist() {
      const me = this;
      me.isSearch = false;
      const search = me.search.split(".").join("");
      try {
        me.isBusy2 = true;
        const { data } = await me.accompanistRepository.search(search);

        if (data) {
          me.formData.names = data?.names;
          me.formData.last_names = data?.last_names;
          me.formData.type_identification = data?.type_identification;
          me.formData.identification = data?.identification;
          me.formData.cellphone = data?.cellphone;
          me.formData.age = data?.age;
          me.formData.eps = data?.eps;
          me.formData.family = data?.family;
          me.formData.cellphone_family = data?.cellphone_family;
        } else {
          me.formData.names = "";
          me.formData.last_names = "";
          me.formData.type_identification = null;
          me.formData.identification = me.search;
          me.formData.cellphone = "";
          me.formData.age = null;
          me.formData.eps = null;
          me.formData.family = null;
          me.formData.cellphone_family = null;
        }
        me.isSearch = true;
        me.search = "";
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    clearField() {
      const me = this;
      me.isSearch = false;
      me.formData.type_identification = null;
      me.formData.identification = "";
      me.formData.cellphone = "";
      me.formData.age = null;
      me.formData.eps = null;
      me.formData.family = null;
      me.formData.cellphone_family = null;
      me.formData.accompanistUuid = me.item.uuid;
      me.formData.names_baby = "";
      me.formData.last_names_baby = "";
      me.formData.type_identification_baby = "Registro civil";
      me.formData.identification_baby = "";
      me.formData.age_baby = "";
      me.formData.eps_baby = "";
    }
  },
};
</script>

<style>
.box-regiter {
  background-image: linear-gradient(to right bottom, #09345f, #09345f, #09345f, #09345f, #09345f, #10355d, #16375a, #1b3858, #253b53, #2f3d4d, #373f47, #404142);
  border-radius: 0.5rem;
  padding: 10px;
}
</style>
