<template>
  <div>
    <list-register-accompanist
      v-if="isRegister"
      :record="record" 
      :accompanists="accompanists"
      :travelUuid="travelUuid"
      @updateList="updateList"
     />
    <search-register
      v-else
      :statusMsgError="statusMsgError"
      :isBusy="isBusy"
      :msgError="msgError"
      @search="search"
      @closedPanel="closedPanel"
    />
  </div>
</template>

<script>
import SearchRegister from "./components/SearchRegister";
import ListRegisterAccompanist from "./components/ListRegisterAccompanist";

export default {
  inject: ["registerAccompanistRepository"],
  components: {
    SearchRegister,
    ListRegisterAccompanist,
  },

  data() {
    const me = this;
    return {
      isRegister: false,
      statusMsgError: false,
      isBusy: false,
      msgError: "",
      record: {},
      accompanists: [],
      formData: {
        identification: "",
        enterpriseUuid: me.$route.params.enterpriseUuid,
        departure_date: null,
      },
      travelUuid: null,
    };
  },

  methods: {
    async search(formData, type = null) {
      const me = this;
      try {
        me.accompanists = [];
        me.isBusy = true;
        me.statusMsgError = false;
        me.formData = { ...me.formData, ...formData };
        
        let { data } = await me.registerAccompanistRepository.search(me.formData.identification, me.formData.enterpriseUuid, me.formData.departure_date);
        
        if (data.length > 0) {
          const record = data[0];
          me.travelUuid = record.travelUuid;
          me.record.client = record.client.names + " " + record.client.last_names;
          me.record.enterprise = record.travel.enterprise.name;
          let count = 1;
          data.forEach(el => {
            el.chairSale.forEach(x => {
              me.accompanists.push({
                uuid: x.chairSaleAccompanist.accompanistUuid,
                number: count++
              })
            })
          });
          me.isRegister = true;
        } else {
          me.msgError = "No tienes registros pendientes.";
          me.statusMsgError = true;
          if (type) window.location.reload();
        }
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    closedPanel() {
      const me = this;
      me.statusMsgError = false;
    },
    updateList() {
      const me = this;
      me.search(me.formData, "refresh");
    }
  },

  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style>
@import "../auth/login/login.css";

.input-date {
  padding-left: 10px !important;
}
</style>
